import type { UserDto } from "@clarinet/swagger-gen/auth";

export type TagItem = {
    text: string;
    error: boolean;
    info: boolean;
};

export function extractUserTags(user?: UserDto): string[] {
  const individualTags: string[] = [];
  if (!user) return individualTags;
  if (user.lockedOut === true) {
    individualTags.push("Locked Out");
  }
  if (user.isLoggedIn === true) {
    individualTags.push("Logged In");
  }
  if (user.mfaEnabled) {
    individualTags.push("MFA");
  }
  if (user.disabled) {
    individualTags.push("Disabled");
  }
  if (user.subscriberAdministrator) {
    individualTags.push("Admin");
  }
  if (user.externalServicer) {
    individualTags.push("External");
  }

  if (user.invitationState === "Invited") {
    individualTags.push("Invited");
  }
  if (user.invitationState === "InviteExpired") {
    individualTags.push("Invite Expired");
  }
  return individualTags;
}

export function tagNameToTagItem(tag: string): TagItem {
  const tagItem = { text: tag, error: false, info: false, success: false };

  switch (tag) {
    case "Disabled":
      tagItem.error = true;
      break;
    case "Logged In":
      tagItem.success = true;
      break;
    default:
      tagItem.info = true;
      break;
  }

  return tagItem;
}
