import Vue from "vue";
import { CLReceivedInvite } from "@clearlife-limited/ui-library";
import { handleServerError } from "@clarinet/ts/Components/ServerError";
import { InviteApi } from "@clarinet/ts/api";

export default Vue.extend({
  components: {
    ClReceivedinvite: CLReceivedInvite,
  },
  data() {
    return {
      invalidInviteCodeMessage: "",
      invalidInviteCodeTimeout: <ReturnType<typeof setTimeout> | null>null,
      emailAddress: <string | null>null,
      loading: false,
      userId: <string | null>null,
    };
  },
  async mounted() {
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId as string;
      const response = await handleServerError(
        InviteApi.receiveInviteByUserId(this.userId)
      );
      this.emailAddress = response.data.emailAddress ?? "";
    }
  },
  methods: {
    async createAccount(inviteCode: string, emailAddress: string) {
      this.invalidInviteCodeMessage = "";
      this.invalidInviteCodeTimeout && clearTimeout(this.invalidInviteCodeTimeout);

      this.loading = true;
      try {
        const response = await handleServerError(
          InviteApi.receiveInvite({
            inviteCode,
            emailAddress,
          },
          { validateStatus: (status: number) => status === 200 || status === 400 }
          )
        );

        if (response.status === 200) {
          this.$router.replace(
            `/Users/CompleteInvite?userId=${this.userId || response.data.userId}&e=${
              response.data.emailAddress
            }&t=${response.data.acceptanceToken}`
          );
        } else {
          this.invalidInviteCodeMessage = response.data as string;
          console.log(this.invalidInviteCodeMessage);
          this.invalidInviteCodeTimeout && clearTimeout(this.invalidInviteCodeTimeout);
          this.invalidInviteCodeTimeout = setTimeout(() => (this.invalidInviteCodeMessage = ""), 5000);
        }
      } finally {
        this.loading = false;
      }
    },
    returnToLogin() {
      this.$router.go(-2);
    },
  },
});
