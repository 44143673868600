import { computed, ref, type Ref } from "vue";
import { defineStore } from "pinia";

const loginReturnUrlKey = "LOGIN_RETURN_URL";
const resetSuccessUsernameKey = "RESET_SUCCESS_USERNAME";
const resetSuccessTypeKey = "RESET_SUCCESS_TYPE";
const userIdKey = "USER_ID";

const sessionStoreBackedComputed = <T extends string>(ref: Ref<T | null>, key: string, isValid: (v: T) => boolean = () => true) => computed({
  get: (): T | null => {
    const returnUrl = sessionStorage.getItem(key) as T ?? ref.value;
    if (typeof isValid === "function" && !isValid(returnUrl)) {
      sessionStorage.removeItem(key);
      ref.value = null;
      return null;
    }
    return returnUrl;
  },
  set: (value: T | null) => {
    if (!value || typeof isValid === "function" && !isValid(value)) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }

    ref.value = value;
  }
});

export type ResetType = "password" | "mfa";

export const useAuthStore = defineStore("auth", () => {
  const _resetSuccessType = ref<ResetType | null>(sessionStorage.getItem(resetSuccessTypeKey) as ResetType);
  const _resetSuccessUsername = ref<string | null>(sessionStorage.getItem(resetSuccessUsernameKey));
  const _returnUrl = ref<string | null>(sessionStorage.getItem(loginReturnUrlKey));
  const _userId = ref<string | null>(sessionStorage.getItem(userIdKey));

  return {
    resetSuccessType: sessionStoreBackedComputed<ResetType>(_resetSuccessType, resetSuccessTypeKey),
    resetSuccessUsername: sessionStoreBackedComputed(_resetSuccessUsername, resetSuccessUsernameKey),
    // Can be removed post 3.0 release.
    // Use the id_token response type to determine whether this is a pre-WebCore redirect url. If it is, it's not valid.
    returnUrl: sessionStoreBackedComputed(_returnUrl, loginReturnUrlKey, v => v === null || !v.toString().includes("%20id_token%20")),
    userId: sessionStoreBackedComputed(_userId, userIdKey),
  };
});
