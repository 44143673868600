import type { Validator } from "@clearlife-limited/ui-library";
import type { IPasswordComponent } from "@clarinet/ts/Components/Password";
import {
  CLAlert,
  CLButton,
  CLCard,
  CLConfirmDialog,
  CLConfirmPassword,
  CLKeyline
} from "@clearlife-limited/ui-library";
import Vue from "vue";
import ApiKeys from "../Components/ApiKeys.vue";
import MfaAdmin from "../Components/MfaAdmin.vue";
import Password from "../Components/Password.vue";
import EmailPreferences from "@clarinet/ts/Components/EmailPreferences.vue";
import UIPreferences from "@clarinet/ts/Components/UIPreferences.vue";
import { LoginApi, ChangeDetailsApi } from "@clarinet/ts/api";

export default Vue.extend({
  components: {
    EmailPreferences,
    Password: Password,
    ApiKeys: ApiKeys,
    MfaAdmin: MfaAdmin,
    ClAlert: CLAlert,
    ClButton: CLButton,
    ClCard: CLCard,
    ClConfirmDialog: CLConfirmDialog,
    ClConfirmPassword: CLConfirmPassword,
    CLKeyline,
    UIPreferences,
  },
  data() {
    return {
      currentPassword: <string | null>null,
      hasApiKeyAccess: false,
      hasErrors: false,
      passwordChanged: false,
      passwordConfirmed: false,
      incorrectConfirmedPassword: false,
      confirmLoading: false,
      errorMessage: [] as string[],
      valid: false,
    };
  },
  computed: {
    passwordComponent(): IPasswordComponent {
      return this.$refs.password as unknown as IPasswordComponent;
    },
    passwordForm(): Validator {
      return this.$refs.passwordForm as unknown as Validator;
    },
  },
  methods: {
    async confirmPassword(password: string) {
      this.confirmLoading = true;
      this.passwordConfirmed = false;

      try {
        const response = await LoginApi.confirmIdentity({ password });
        this.passwordConfirmed = response.status === 200;
        this.currentPassword = password;
      } finally {
        this.incorrectConfirmedPassword = !this.passwordConfirmed;
        this.confirmLoading = false;
      }
    },
    async changePassword() {
      this.passwordForm.validate();
      if (!this.valid) return;

      this.hasErrors = false;
      this.errorMessage = [];
      this.passwordChanged = false;

      const payload = {
        ...this.passwordComponent.getData(),
        currentPassword: this.currentPassword,
      };
      const response = await ChangeDetailsApi.changePassword(payload);

      if (response.data.success) {
        this.passwordChanged = true;
        this.currentPassword = payload.newPassword;
      } else {
        this.hasErrors = true;
        this.errorMessage = response.data.faults ?? [];
      }

      this.passwordForm.reset();
    },
  },
});
