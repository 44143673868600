import { mapStores } from "pinia";
import { download } from "../Utility/FileDownloader";
import type { UserDto } from "@clarinet/swagger-gen/auth";
import mfaWithCompanyCheckComponent from "@clarinet/ts/Mixins/MfaWithCompanyCheckComponent";
import type { GridOptions, ColDef, RowSelectedEvent, GridApi, RowClassParams } from "@ag-grid-community/core";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import UserOptionsRenderer from "../Components/Renderers/UserOptionsRenderer.vue";
import type { UserOptionsRendererParams } from "@clarinet/ts/Components/Renderers/UserOptionsRenderer";
import RouterLinkRender from "@clarinet/ts/Components/Renderers/RouterLinkRender.vue";
import type { RouterLinkRenderParams } from "@clarinet/ts/Components/Renderers/RouterLinkRender-types";
import UserStatusTagRenderer from "../Components/Renderers/UserStatusTagRenderer.vue";
import type { CLTabData, CLMenuItemData } from "@clearlife-limited/ui-library";
import { createActionColDef } from "@clearlife-limited/ui-library";
import {
  CLButton,
  CLProgressDialog,
  CLMenu,
  CLTabs,
  CLAlert,
  CLKeyline,
  CLTable,
  CLEnableMfa,
  CLSearchbox,
  dateFormatter,
  CLRelativeTimeRenderer,
} from "@clearlife-limited/ui-library";
import { caseInsensitiveComparator } from "@clarinet/ts/Utility/Comparators";
import { LoginApi, MfaApi, OrganisationsApi, UsersApi } from "@clarinet/ts/api";
import { type ResetType, useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";

interface UserFilter extends Function {
    (u: UserDto): boolean;
}

interface UserFilteringTab extends CLTabData {
  filterName: string|null;
}

export type CallbackAlert = {
  userName: string;
  isEnabling: boolean;
}

export default mfaWithCompanyCheckComponent.extend({
  components: {
    "cl-button": CLButton,
    "cl-menu": CLMenu,
    CLTabs,
    "cl-alert": CLAlert,
    "cl-keyline": CLKeyline,
    "cl-table": CLTable,
    "cl-progressdialog": CLProgressDialog,
    "cl-enablemfa": CLEnableMfa,
    CLSearchbox,
    UserOptionsRenderer,
    UserStatusTagRenderer,
    RouterLinkRender,
    CLRelativeTimeRenderer,
  },
  data() {
    return {
      loadingSelf: true,
      userSystemAdministrator: false,
      organisationUserLimit: <number | null>null,
      loadingUsers: false,
      users: <UserDto[]>[],
      resetUserMessage: <string | null>null,
      resetUserType: <ResetType | null>null,
      defaultMfa: {
        authenticatorUri: "",
        sharedKey: "",
      },
      userTabs: <UserFilteringTab[]>[
        {
          text: "All",
          filterName: null
        },
        {
          text: "Active",
          filterName: "active"
        },
        {
          text: "Admin",
          filterName: "admin"
        },
        {
          text: "Disabled",
          filterName: "disabled"
        },
        {
          text: "Logged In",
          filterName: "loggedIn"
        },
      ],
      agGridModules: [SetFilterModule],
      showAuditReportDialog: false,
      showAuditReportError: false,
      showUserUpdateError: false,
      userLimitReached: false,
      showTakeOwnershipMessage: false,
      showTakeOwnershipCount: 0,
      qeId: "users-list",
      callbackAlert: <undefined | CallbackAlert>undefined,
      searchBoxText: <string | null>null,
      searchTimeout: <ReturnType<typeof setTimeout> | undefined>undefined, // can call clearTimeout with undefined without error
      lsUrl: <string | undefined>undefined,
      gridApi: <GridApi<UserDto> | undefined>undefined,
      targetUser: <UserDto | undefined>undefined,
      userReseted: false
    };
  },
  async created() {
    // We need to load MFA status, Org list
    await this.reload();

    const resetUser = this.authStore.resetSuccessUsername;
    const resetType = this.authStore.resetSuccessType;
    if (resetUser) {
      this.resetUserMessage = resetUser;
      this.userReseted = true;
      this.resetUserType = resetType;
      this.authStore.resetSuccessUsername = null;
      this.authStore.resetSuccessType = null;
    }
    const response = await LoginApi.applicationHome("ClariNet LS");
    if (response.status === 200) {
      this.lsUrl = response.data.replace(/\/+$/, "");
    }

    this.setSearch();
  },
  methods: {
    onRowClicked({ data }: RowSelectedEvent<UserDto>) {
      // username is empty for invite users
      if (data?.userName) {
        this.$router.push({ name: "editUser", params: { userName: data.userName } })
      }
    },
    async reload(callbackAlert?: CallbackAlert) {
      this.loadingSelf = true;
      if (callbackAlert) {
        this.callbackAlert = callbackAlert;
      }
      const { data } = await  UsersApi.getUserSelf();
      this.targetUser = data.targetUser;
      this.userSystemAdministrator = data.isSelfSystemAdministrator === true;
      this.organisationUserLimit = data.organisationUserLimit ?? null;
      this.userLimitReached = data.userLimitReached === true;
      this.loadingSelf = false;
      await this.reloadUsers(); // must be called after target user is set

    },
    async reloadUsers() {
      this.loadingUsers = true;
      const response = await UsersApi.all();
      this.users = response.data.filter(user => user.id !== this.targetUser?.id);
      this.loadingUsers = false;
      await this.$nextTick();
      this.gridApi?.refreshCells({ columns: ["options"], force: true });
    },
    updateError() {
      this.showUserUpdateError = true;
    },
    async getAuditReport() {
      this.showAuditReportDialog = true;
      try {
        await download("/api/Users/AuditReport", undefined, "GET");
      } catch (exceptionVar) {
        this.showAuditReportError = true;
      } finally {
        this.showAuditReportDialog = false;
      }
    },
    addUser() {
      this.$router.push("/Users/Add");
    },
    inviteUser() {
      this.$router.push("/Users/Invite");
    },
    externalAccess() {
      window.open(`${this.lsUrl}/user/administration/ExternalAccess`, "_blank");
    },
    constructRouteObject(newTab?: UserFilteringTab) {
      const selectedTab = (newTab ?? this.currentFilterTab);
      const route = {
        name: "UsersList",
        params: <{ filter?: string; search?: string }>{}
      };
      if (selectedTab.filterName) {
        route.params = { filter: selectedTab.filterName };
      }
      if (this.searchBoxText) {
        route.params.search = this.searchBoxText;
      }
      return route;
    },
    async enableMfa() {
      await OrganisationsApi.setMfaRequired(true);
      this.mfa.required = true;
    },
    async disableMfa() {
      await OrganisationsApi.setMfaRequired(false);
      this.mfa.required = false;
    },
    async startDefaultMfaReset() {
      const response = await MfaApi.getResetForOrganizationDefault();
      if (response.status === 200) {
        this.defaultMfa.authenticatorUri = response.data.authenticatorUri || "";
        this.defaultMfa.sharedKey = response.data.sharedKey || "";
      }
    },
    async completeDefaultMfaReset(
      authenticatorCode: string,
      onAuthenticatorCodeValidated: (valid: boolean, newRecoveryCodes?: string[]) => void
    ) {
      const response = await MfaApi.resetForOrganizationDefault({
        code: this.defaultMfa.sharedKey,
        acceptanceToken: authenticatorCode,
      });
      onAuthenticatorCodeValidated(response.status === 200);
    },
    showTakeOwnershipSuccess(updatedCount: number) {
      this.showTakeOwnershipCount = updatedCount;
      this.showTakeOwnershipMessage = true;
    },
    setSearch() {
      const searchTerm = this.$route.params?.search;
      this.gridApi?.setGridOption("quickFilterText", searchTerm ?? "");
      this.searchBoxText = searchTerm;
    }
  },
  computed: {
    filterIsActive(): boolean {
      return (this.searchBoxText ?? "").trim() !== "";
    },
    gridOptions(): GridOptions<UserDto> {
      return {
        domLayout: "autoHeight",
        defaultColDef: {
          sortable: true,
        },
        getRowId: ({ data }) => data.id?.toString() || data.userName as string,
        pagination: true,
        paginationPageSize: 10,
        rowClassRules: {
          "row-clickable": (params: RowClassParams<UserDto>) => !!params.data?.userName,
        },
        onGridReady: event => this.gridApi = event.api,
        autoSizeStrategy: {
          type: "fitCellContents",
          colIds: ["status"]
        },
        onPaginationChanged: event => event.api.autoSizeColumn("status")
      }
    },
    pinnedRows(): UserDto[] {
      const hasFiltered = this.filterIsActive && this.searchTimeout === undefined;
      return this.targetUser && !hasFiltered ? [this.targetUser].filter(this.currentFilter) : [];
    },
    preFilteredUsers(): UserDto[] {
      return this.users.filter(this.currentFilter);
    },
    columnDefs(): ColDef[] {
      const reloadCallback = this.reload.bind(this);
      const updateErrorCallback = this.updateError.bind(this);
      const showTakeOwnershipCallback = this.showTakeOwnershipSuccess.bind(this);
      const baseColumns: ColDef[] = [
        createActionColDef<ColDef>({
          field: "userName",
          flex: 1,
          colId: "options",
          width: 90,
          cellRenderer: "UserOptionsRenderer",
          cellRendererParams: <UserOptionsRendererParams>{
            qeIdPrefix: this.qeId,
            isCurrentUserSystemAdmin: this.userSystemAdministrator,
            reloadCallback,
            updateErrorCallback,
            showTakeOwnershipSuccess: showTakeOwnershipCallback,
            userLimitReached: this.userLimitReached,
          },
        }),
        {
          headerName: "Username",
          field: "userName",
          flex: 2,
          minWidth: 100,
          sort: "asc",
          filter: "agTextColumnFilter",
          comparator: caseInsensitiveComparator,
          cellStyle: (params) => {
            if (params.data.isSelf) {
              return { "font-weight": "bold" };
            }
            return null;
          },
          cellRenderer: "RouterLinkRender",
          cellRendererParams: <RouterLinkRenderParams>{
            getLink: (routeData) => ({ ...routeData, name: "editUser" }),
            canDisplayLink: (routeData) => !!routeData.params?.userName,
          },
        },
        {
          headerName: "Firstname",
          field: "firstName",
          flex: 2,
          minWidth: 100,
          filter: "agTextColumnFilter",
          comparator: caseInsensitiveComparator,
        },
        {
          headerName: "Lastname",
          field: "lastName",
          flex: 2,
          minWidth: 100,
          filter: "agTextColumnFilter",
          comparator: caseInsensitiveComparator,
        },
        {
          headerName: "Email Address",
          field: "email",
          flex: 3,
          minWidth: 150,
          filter: "agTextColumnFilter",
          comparator: caseInsensitiveComparator,
        },
        {
          headerName: "Last Login",
          field: "lastLogin",
          flex: 2,
          minWidth: 100,
          cellRenderer: "CLRelativeTimeRenderer",
          valueFormatter: dateFormatter,
          useValueFormatterForExport: true,
        },
        {
          headerName: "Status",
          colId: "status",
          flex: 2,
          minWidth: 100,
          resizable: false,
          cellRenderer: "UserStatusTagRenderer",
        },
      ];

      if (this.userSystemAdministrator) {
        baseColumns.splice(1, 0, {
          headerName: "Client",
          field: "organisationName",
          filter: true,
          flex: 2,
          minWidth: 100,
          comparator: caseInsensitiveComparator,
        });
      }

      return baseColumns;
    },
    userActions(): CLMenuItemData[] {
      const result: CLMenuItemData[] = [];

      result.unshift({ text: "Download user audit", event: "download-audit" });
      if (!this.loadingSelf) {
        if (this.userSystemAdministrator) {
          result.unshift({ text: "Add User", event: "add-user" });
        } else if (!this.userLimitReached) {
          result.unshift({ text: "Create user invite", event: "invite-user" });
        }
      }

      if (this.lsUrl) {
        result.push({ text: "Manage external access", event: "external-access", icon: "mdi-open-in-new" });
      }

      return result;
    },
    currentFilter(): UserFilter {
      switch (this.$route.params?.filter) {
        case "active":
          return (user: UserDto) => !user.disabled;
        case "admin":
          return (user: UserDto) => user.subscriberAdministrator ?? false;
        case "disabled":
          return (user: UserDto) => user.disabled ?? false;
        case "loggedIn":
          return (user: UserDto) => user.isLoggedIn ?? false;
        default:
          return () => true;
      }
    },
    currentFilterTab: {
      get(): UserFilteringTab {
        const selectedTab = this.userTabs.find(ut => ut.filterName === this.$route.params?.filter);
        if (selectedTab) return selectedTab;
        return this.userTabs[0];
      },
      set(data: UserFilteringTab) {
        const route = this.constructRouteObject(data);
        this.$router.replace(route);
      },
    },
    ...mapStores(useAuthStore),
  },
  watch: {
    searchBoxText() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        const route = this.constructRouteObject();
        this.$router.replace(route);
      }, 350);
    },
    $route() {
      this.setSearch();
    }
  }
});
