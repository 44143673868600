<template>
  <div>
    <h2>UI Preferences</h2>
    <CLSelect
      v-model="selectedFontSize"
      label="Font size"
      item-text="text"
      item-value="value"
      :items="fontSizeItems"
      :loading="fontSizeLoading || showLoadingAnim"
      :format-label="false"
      hint="This will affect the font sizes using the new design system."
      @input="handleFontSizeChange"
    />
    <CLAlert v-if="fontSizeChanged" type="info" class="log-out-msg" :dismissable="false">
      You need to log out and back in for the font size change to take affect on all other tabs.
    </CLAlert>
  </div>
</template>

<script setup lang="ts">
import { CLSelect, CLAlert } from "@clearlife-limited/ui-library";
import { computed, ref } from "vue";
import { getLocalFontSize, updateFontSize } from "../Utility/UIPreferences";

type Option = { text: string; value: string;}

const fontSizeItems = computed<Option[]>(() => ([
  { text: "Default", value: "default" },
  { text: "Compact", value: "compact" },
]));
const fontSizeLoading = ref(false);
const showLoadingAnim = ref(false);
const selectedFontSize = ref<string>(getLocalFontSize());
const fontSizeChanged = ref(false);

function handleFontSizeChange(newValue: string) {
  if (!newValue) return;
  fontSizeLoading.value = true;
  showLoadingAnim.value = true;
  setTimeout(() => {
    showLoadingAnim.value = false; // in case the loading is too fast, show the loading animation for at least 500ms
    fontSizeChanged.value = true;
  }, 500);
  updateFontSize(newValue);
  fontSizeLoading.value = false;
}
</script>

<style lang="scss" scoped>
.log-out-msg {
  width: 100%;
}
</style>
