<template>
  <v-row v-if="showError">
    <v-spacer />
    <v-col>
      <ClAlert
        v-model="showError"
        type="error"
      >
        {{ errorMessage }}
      </ClAlert>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script lang="ts">
import type { AxiosError } from "axios";
import { CLAlert } from "@clearlife-limited/ui-library";
import { handleServerErrorEvent } from "./ServerError";
import emitter from "tiny-emitter/instance";
import Vue from "vue";

export default Vue.extend({
  components: {
    ClAlert: CLAlert,
  },
  data() {
    return {
      showError: false,
      errorMessage: "",
    };
  },
  watch: {
    showError(newValue: boolean) {
      if (!newValue) this.errorMessage = "";
    },
  },
  mounted() {
    emitter.on(handleServerErrorEvent, this.handleError);
  },
  beforeDestroy() {
    emitter.off(handleServerErrorEvent, this.handleError);
  },
  methods: {
    handleError(error: AxiosError<unknown>) {
      if (error.response && error.response.status >= 400) {
        const data = error.response.data;

        if (data instanceof Object && "message" in data && typeof data.message === "string") {
          this.errorMessage = data.message;
        } else {
          this.errorMessage = typeof error.response.data === "string" ? error.response.data : "An error unknown error occurred";
        }
        this.showError = true;
      }
    },
  },
});
</script>
