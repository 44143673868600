import Vue from "vue";
import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";
import { CLEnterMFACode, CLEnterMFARecoveryCode, CLNoMFAAccess } from "@clearlife-limited/ui-library";
import { MfaApi } from "@clarinet/ts/api";
import { fetchAndUpdateFontSize } from "../Utility/UIPreferences";

export default Vue.extend({
  components: {
    ClEntermfa: CLEnterMFACode,
    ClEntermfarecoverycode: CLEnterMFARecoveryCode,
    ClNomfa: CLNoMFAAccess,
  },
  data() {
    return {
      recoveryMode: false,
      noMfa: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    async login(code: string, onError?: (errorMessage: string[]) => void) {
      const returnUrl = this.authStore.returnUrl;
      if (!returnUrl) {
        console.error("LoginMFA: No return URL found in session storage");
        if (onError) {
          onError(["Something went wrong with your login request, please reload the page and try again"]);
        }
        return;
      }

      const payload = {
        twoFactorCode: code,
        returnUrl: returnUrl,
        rememberMe: this.$route.params.rememberMe === "true",
      };
      const response = this.recoveryMode
        ? await MfaApi.recovery(payload)
        : await MfaApi.login(payload);

      if (response.status === 200) {
        await fetchAndUpdateFontSize();
      }

      if (response.data.returnToApplication) {
        this.authStore.returnUrl = null;

        // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
        if (returnUrl.startsWith("/connect")) {
          location.assign(returnUrl);
        } else {
          this.$router.push(returnUrl);
        }
      }

      if (response.data.restartLogin) {
        this.$router.go(-1);
        return;
      }

      if (onError && response.data.faults && response.data.faults.length) {
        onError(response.data.faults);
      }
    },
    initRecovery() {
      this.recoveryMode = true;
    },
    backToLogin() {
      this.$router.go(-1);
    },
    noRecovery() {
      this.noMfa = true;
    },
  },
});
