import Vue from "vue";
import { CLYouCanNowLogin } from "@clearlife-limited/ui-library";

export default Vue.extend({
  components: {
    ClYoucannowlogin: CLYouCanNowLogin,
  },
  methods: {
    login() {
      this.$router.replace("/Login/ClariNet%20LS");
    },
  },
});
