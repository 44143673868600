import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";
import { CLMFARequired } from "@clearlife-limited/ui-library";
import mfaComponent from "@clarinet/ts/Mixins/MfaComponent";

export default mfaComponent.extend({
  components: {
    "cl-mfarequired": CLMFARequired,
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    onSuccess() {
      const returnUrl = this.authStore.returnUrl;
      this.authStore.userId = null;
      this.authStore.returnUrl = null;
      if (!returnUrl) {
        console.error("EnrolMfa: No return URL found in session storage");
        return;
      }

      // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
      if (returnUrl.startsWith("/connect")) {
        location.assign(returnUrl);
      } else {
        this.$router.push(returnUrl);
      }
    },
  },
});
