import Vue from "vue";
import type { ICellRendererParams } from "@ag-grid-community/core";
import type { CLMenuItemData } from "@clearlife-limited/ui-library";
import { CLMenu } from "@clearlife-limited/ui-library";
import type { UserDto } from "@clarinet/swagger-gen/auth";
import { InvitationState } from "@clarinet/swagger-gen/auth";
import type { CallbackAlert } from "../../Pages/Users";
import { InviteApi, UsersApi } from "@clarinet/ts/api";
import http from "axios";
export interface UserOptionsRendererParams {
  qeIdPrefix: string;
  userLimitReached: boolean;
  isCurrentUserSystemAdmin: boolean;

  reloadCallback(callbackAlert?: CallbackAlert): void;
  updateErrorCallback(): void;

  showTakeOwnershipSuccess(updatedCount: number): void;
}
type StrongParams = UserOptionsRendererParams &
  Omit<ICellRendererParams, "value" | "data"> & {
    value: string;
    data: UserDto;
  };

export default Vue.extend({
  components: {
    ClMenu: CLMenu,
  },
  computed: {
    disableEnableUserButton(): string {
      return String(this.user.userLimitReached);
    },
    menu(): CLMenuItemData[] {
      if (this.user?.invitationState === InvitationState.Invited || this.user?.invitationState === InvitationState.InviteExpired) {
        return [
          { text: "Resend invite", event: "resend-invite" },
          { text: "Delete invite", event: "delete-invite" },
        ];
      }
      const menuItems: CLMenuItemData[] = [{ text: "Edit user & roles", event: "edit-user" }];

      if (this.user.lockedOut) {
        menuItems.push({ text: "Unlock user", event: "unlock-user" });
      }
      if (this.user.disabled && !this.strongParams.userLimitReached && !this.user.isSelf) {
        menuItems.push({ text: "Enable user", event: "enable-user" });
      }
      if (this.user.disabled && !this.user.isSelf && !this.strongParams.isCurrentUserSystemAdmin) {
        menuItems.push({ text: "Take ownership of data", event: "take-ownership" });
      }
      if (!this.user.disabled && !this.user.isSelf) {
        menuItems.push({ text: "Disable user", event: "disable-user" });
      }
      return menuItems;
    },
    rowQeId(): string {
      return `${this.strongParams.qeIdPrefix}-item-${this.strongParams.rowIndex}-menu`;
    },
    strongParams(): StrongParams {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <StrongParams>(<any>this).params;
    },
    user(): UserDto {
      return this.strongParams.data;
    },
    userName(): string {
      return this.strongParams.value;
    },
  },
  methods: {
    allow400(status: number) {
      return (status >= 200 && status < 300) || status === 400;
    },
    editUser() {
      this.$router.push({ name: "editUser", params: { userName: this.userName } });
    },
    async enableUser() {
      await this.toggleUserEnabledStatus(true);
    },
    async disableUser() {
      await this.toggleUserEnabledStatus(false);
    },
    async toggleUserEnabledStatus(enabled: boolean) {
      const response = await UsersApi.updateUser(this.userName, enabled, { validateStatus: this.allow400 });

      if (response.status === 400) {
        this.strongParams.updateErrorCallback();
      } else {
        this.strongParams.reloadCallback({ isEnabling: enabled, userName: this.strongParams.data.userName || "" });
      }
    },
    async takeOwnership() {
      const firstResponse = await UsersApi.takeOwnership(this.userName);

      if (firstResponse.status !== 200) {
        this.strongParams.updateErrorCallback();
        return;
      }

      const lsApiUri = firstResponse.data;

      const secondResponse = await http.post<number>(lsApiUri, undefined, { withCredentials: true });

      if (secondResponse.status !== 200) {
        this.strongParams.updateErrorCallback();
        return;
      }

      this.strongParams.showTakeOwnershipSuccess(secondResponse.data);
    },
    async unlockUser() {
      await UsersApi.unlockUser(this.userName);
      this.user.lockedOut = false;
    },
    async deleteInvite() {
      await InviteApi.delete(this.user.id as string);
      this.strongParams.reloadCallback();
    },
    async resendInvite() {
      this.$router.push({ name: "userReinvite", params: { userId: this.user.id as string } });
    },
  },
});
