
// If you haven't disabled the module preload polyfill, you also need to import the polyfill in your entry https://vitejs.dev/guide/backend-integration.html
import "vite/modulepreload-polyfill"; // // add the beginning of your app entry

import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import { createPinia, PiniaVuePlugin } from "pinia";
import { setUpSentry } from "./Utility/Sentry";
import Vuetify from "vuetify";
import { CLPage, CLStack, CLHeader, createUI } from "@clearlife-limited/ui-library";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { handle401 } from "@clarinet/ts/Utility/AxiosInterceptors";
import { router } from "./router";
import App from "./App.vue";

// Import styles _after_ router to ensure that the Vuetify component styles are loaded first when serving.
import "./main.scss";

// Set ag-grid license... It is a known limitation that it is distributed with the code.
import { AG_Grid_LicenseKey } from "@clearlife-limited/ui-library";
LicenseManager.setLicenseKey(
  AG_Grid_LicenseKey
);

// Ensure Identity responds with 401/3 rather than redirects.
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use(undefined, handle401);

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.component("ClPage", CLPage);
Vue.component("ClStack", CLStack);
Vue.component("ClHeader", CLHeader);
Vue.use(createUI);

setUpSentry();

new Vue({
  vuetify: new Vuetify({ icons: { iconfont: "mdi" } }),
  router,
  el: "#app",
  render: h => h(App),
  pinia: createPinia(),
});
