import Vue from "vue";
import Password from "../Components/Password.vue";
import {
  CLAlert,
  CLForgotPassword,
  CLForgotPasswordEmailSent,
  CLForgotPasswordUnableToReset,
  CLSetupNewPassword,
  CLYouCanNowLogin,
} from "@clearlife-limited/ui-library";
import { LoginApi } from "@clarinet/ts/api";
import axios from "axios";

export default Vue.extend({
  components: {
    Password: Password,
    ClForgotpassword: CLForgotPassword,
    ClEmailsent: CLForgotPasswordEmailSent,
    ClNoreset: CLForgotPasswordUnableToReset,
    ClSetuppassword: CLSetupNewPassword,
    ClNowlogin: CLYouCanNowLogin,
    ClAlert: CLAlert,
  },
  data() {
    return {
      resetToken: <string | null>null,
      passwordReset: false,
      errorMessage: "",
      cannotReset: false,
    };
  },
  computed: {
    hasError: {
      get(): boolean { return !!this.errorMessage; },
      set(value: boolean) { if (!value) this.errorMessage = ""; }
    }
  },
  mounted() {
    if (this.$route.query.resetToken) {
      this.resetToken = this.$route.query.resetToken as string;
    }
  },
  methods: {
    async requestResetEmail(userName: string) {
      this.errorMessage = "";
      const response = await LoginApi.forgotPassword({ userName });
      if (response.status === 200) {
        if (response.data === "Reset not available") {
          this.cannotReset = true;
        } else {
          this.passwordReset = true;
        }
      } else {
        this.errorMessage = response.data;
      }
    },
    async completePasswordReset(password: string) {
      this.errorMessage = "";
      try {
        await LoginApi.forgotPassword({
          userName: this.$route.query.userName as string,
          token: this.resetToken,
          newPassword: password,
        });
        this.passwordReset = true;

      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data) {
          this.errorMessage = error.response.data;
          return;
        }

        this.errorMessage = "An error occurred while resetting your password. Please contact support if this problem persists.";
      }
    },
    forgotUsername() {
      this.$router.replace("/ForgottenUsername");
    },
    returnToLogin() {
      this.$router.replace("/Login/ClariNet%20LS");
    },
    returnToForm() {
      this.passwordReset = false;
      this.cannotReset = false;
    },
  }
});
