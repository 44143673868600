import type { AxiosError, AxiosResponse } from "axios";
import emitter from "tiny-emitter/instance";

export const handleServerErrorEvent = "handle-server-error";

export function handleServerError<T>(promise: Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> {
  promise.catch((error: AxiosError) => {
    emitter.emit(handleServerErrorEvent, error);
  });
  return promise;
}
