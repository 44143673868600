<template>
  <span :class="{ [params?.cssClass || '']: params?.cssClass !== undefined }">
    <router-link v-if="canDisplayLink" :to="link">
      {{ params?.value }}
    </router-link>
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import type { Route } from "vue-router";
import type { RouterLinkRenderParams } from "./RouterLinkRender-types";

export default Vue.extend({
  name: "RouterLinkRender",
  data: () => ({
    params: <null | RouterLinkRenderParams>null,
    link: undefined as Route | undefined,
    canDisplayLink: false,
  }),
  beforeMount() {
    if (!this.params) {
      this.canDisplayLink = false;
      return;
    }
    const routeData = { params: { userName: this.params.value } };
    this.canDisplayLink = this.params.canDisplayLink(routeData);
    this.link = this.params.getLink(routeData);
  }
});
</script>
