import type { ChangeRolesErrorDto } from "@clarinet/swagger-gen/auth"
import Vue from "vue";
import axios from "axios";
import { mapStores } from "pinia";
import type { OrganisationDto, UserDto } from "@clarinet/swagger-gen/auth";
import Roles from "../Components/Roles.vue";
import type { CLTabData } from "@clearlife-limited/ui-library";
import {
  CLIconButton,
  CLTextbox,
  CLCheckbox,
  CLKeyline,
  CLMenu,
  CLButton,
  CLButtonBar,
  CLTabs,
  CLAlert,
  CLConfirmDialog,
  CLSelect,
} from "@clearlife-limited/ui-library";
import type { RoleChanges } from "@clarinet/ts/Components/Roles";
import { OrganisationsApi, RolesApi, UsersApi } from "@clarinet/ts/api";
import { type ResetType, useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";

interface UserEditTabData extends CLTabData {
    selected: boolean;
}

export default Vue.extend({
  components: {
    ClIconButton: CLIconButton,
    ClTextbox: CLTextbox,
    ClCheckbox: CLCheckbox,
    ClKeyline: CLKeyline,
    ClMenu: CLMenu,
    ClButton: CLButton,
    CLButtonBar,
    ClConfirmDialog: CLConfirmDialog,
    CLTabs,
    ClAlert: CLAlert,
    ClSelect: CLSelect,
    Roles,
  },
  data() {
    return {
      userName: "",
      email: "",
      selectedOrganisation: <string | null>null,
      organisations: <OrganisationDto[]>[],
      firstName: "",
      lastName: "",
      contractualUser: false,
      disabled: false,
      hasMfa: false,
      lockedOut: false,
      applyClearlifeMfa: false,
      isUserSelf: false,
      isSelfSystemAdministrator: false,
      error: <string | null>null,
      roleChanges: <RoleChanges>{
        addRoles: [],
        removeRoles: [],
      },
      tabs: <UserEditTabData[]>[
        {
          text: "Edit User",
          selected: true,
        },
        {
          text: "Permissions",
          selected: false,
        },
      ],
      confirmResetUserAuthType: <ResetType | null>null,
    };
  },
  computed: {
    hasError(): boolean {
      return !!this.error;
    },
    userDetailsTab(): UserEditTabData {
      return this.tabs[0];
    },
    permissionsTab(): UserEditTabData {
      return this.tabs[1];
    },
    canSetClearlifeMfa(): boolean {
      return this.isSelfSystemAdministrator && !this.contractualUser;
    },
    ...mapStores(useAuthStore),
  },
  async mounted() {
    this.userName = this.$route.params.userName;
    const response = await UsersApi.getUser(this.userName);
    this.email = response.data.targetUser?.email || "";
    this.selectedOrganisation = response.data.targetUser?.organisationName || "";
    this.firstName = response.data.targetUser?.firstName || "";
    this.lastName = response.data.targetUser?.lastName || "";
    this.contractualUser = response.data.targetUser?.contractualUser || false;
    this.disabled = response.data.targetUser?.disabled || false;
    this.lockedOut = response.data.targetUser?.lockedOut || false;
    this.isUserSelf = response.data.isUserSelf || false;
    this.hasMfa = response.data.targetUser?.mfaEnabled || false;
    this.isSelfSystemAdministrator = response.data.isSelfSystemAdministrator || false;
    if (this.isSelfSystemAdministrator) {
      const orgResponse = await OrganisationsApi.all();
      this.organisations = orgResponse.data;
    }
  },
  methods: {
    cancelEdit() {
      this.$router.go(-1);
    },
    allow400(status: number) {
      return (status >= 200 && status < 300) || status === 400;
    },
    async saveEdit() {
      const payload: UserDto = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        contractualUser: this.contractualUser,
        disabled: this.disabled,
        lockedOut: this.lockedOut,
        organisationName: this.selectedOrganisation,
        applyClearlifeMfa: this.applyClearlifeMfa,
      };

      this.error = null;

      try {
        await UsersApi.patchUser(this.userName, payload);
      } catch (error) {
        if (axios.isAxiosError(error) && typeof error.response?.data === "string") {
          this.error = error.response.data;
          return;
        }
        this.error = "Sorry, something went wrong updating the user's details. Please try again. If you continue to have errors please contact support.";
        return;
      }

      try {
        await RolesApi.changeUserRoles(this.userName, this.roleChanges);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400 && error.response.data) {
          const changeRolesError = error.response.data as ChangeRolesErrorDto;
          this.error = changeRolesError.message;

          if (!!changeRolesError.badRoles && Array.isArray(changeRolesError.badRoles)) {
            this.error += ": " + changeRolesError.badRoles.join(", ");
          }
          this.error = error.response.data;
          return;
        }
        this.error = "Sorry, something went wrong updating the user's permissions. Please try again. If you continue to have errors please contact support.";
        return;
      }

      if (!this.error) this.$router.go(-1);
    },
    async resetUserAuth(type: ResetType) {
      this.confirmResetUserAuthType = null;

      try {
        await UsersApi.resetAuthentication(this.userName, type);
        this.authStore.resetSuccessUsername = this.userName;
        this.authStore.resetSuccessType = type;
        this.$router.go(-1);

      } catch (error) {
        if (axios.isAxiosError(error) && typeof error.response?.data === "string") {
          this.error = error.response.data;
          return;
        }

        this.error = "An unexpected error occurred. Please try again. If you continue to have errors please contact support.";
      }
    },
    changeTab(tab: CLTabData) {
      this.tabs.forEach((t) => (t.selected = t.text === tab.text));
    },
  },
});
