import { defineComponent } from "vue";
import type { Validator } from "@clearlife-limited/ui-library";
import { CLPassword, validatorSetup, getPasswordRules } from "@clearlife-limited/ui-library";

export interface IPasswordComponent {
    getData(): PasswordData;
}
interface PasswordData {
    newPassword: string;
    newPasswordConfirmed: string;
}

export default defineComponent({
  components: {
    ClPassword: CLPassword,
  },
  props: {
    showConfirm: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newPassword: "",
      newPasswordConfirmed: "",
    };
  },
  computed: {
    newPasswordLabel(): string {
      return this.isNew ? "New password" : "Password";
    },
    newPasswordConfirmedLabel(): string {
      return this.isNew ? "Confirm new password" : "Confirm password";
    },
    rules() {
      return validatorSetup({
        newPassword: getPasswordRules("Password"),
        newPasswordConfirmed: getPasswordRules("Password", () => this.newPassword, "Password"),
      });
    },
  },
  methods: {
    getData(): PasswordData {
      return {
        newPassword: this.newPassword,
        newPasswordConfirmed: this.newPasswordConfirmed,
      };
    },
    validateIfEdited() {
      if (this.newPassword && this.newPasswordConfirmed) {
        (this.$refs.form as unknown as Validator)?.validate();
      }
    }
  },
});
