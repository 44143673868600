import Vue from "vue";
import { CLConfirmDialog, CLGridActionButton } from "@clearlife-limited/ui-library";
import type { ICellRendererParams } from "@ag-grid-community/core";

export type DeleteButtonRendererParams = ICellRendererParams & {
    itemName: string;
};

export default Vue.extend({
  components: {
    ClConfirmDialog: CLConfirmDialog,
    ClGridActionButton: CLGridActionButton,
  },
  data() {
    return {
      params: <null | DeleteButtonRendererParams>null,
      showConfirm: false,
    };
  },
  computed: {
    dialogTitle(): string {
      return `Delete ${this.params?.itemName || "Item"}`;
    },
    dialogText(): string {
      return `Are you sure you want to delete this ${this.params?.itemName || "item"}?`;
    },
  },
  methods: {
    invokeDelete() {
      this.params && this.$parent?.$emit("delete-row", this.params.node.id, this.params.rowIndex);
    },
  },
});
