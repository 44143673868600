import Vue from "vue";
import { mapStores } from "pinia";
import { CLLogin, CLAlert } from "@clearlife-limited/ui-library";
import { fetchAndUpdateFontSize } from "../Utility/UIPreferences";
import { LoginApi } from "@clarinet/ts/api";
import { useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";

export const localAppName = "Local";

export default Vue.extend({
  components: {
    CLLogin,
    CLAlert,
  },
  data() {
    return {
      isLocalLogin: false,
      errorMessage: <string[]>[],
      showErrors: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    this.isLocalLogin = (this.$route.params.application || localAppName) === localAppName;

    if (!this.isLocalLogin && !this.authStore.returnUrl) {
      const response = await LoginApi.applicationHome(this.$route.params.application);
      if (response.status === 200) {
        location.assign(response.data);
      }
    }
  },
  methods: {
    async login(loginData: { username: string; password: string; rememberMe: boolean }) {
      this.errorMessage = [];
      this.showErrors = false;

      const returnUrl = this.authStore.returnUrl;
      if (!returnUrl) {
        console.error("Login: No return URL found in session storage");
        this.errorMessage = [
          "Something went wrong with your login request, please reload the page and try again",
        ];
        this.showErrors = true;
        return;
      }

      const response = await LoginApi.login({
        userName: loginData.username,
        password: loginData.password,
        rememberLogin: loginData.rememberMe,
        returnUrl: returnUrl,
      });

      if (response.status === 200 && response.data.actionMfa === false) {
        await fetchAndUpdateFontSize();
      }

      if (response.data.returnToApplication) {
        this.authStore.returnUrl = null;

        // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
        if (returnUrl.startsWith("/connect")) {
          location.assign(returnUrl);
        } else {
          this.$router.push(returnUrl);
        }
      } else if (response.data.enrollMfa) {
        this.$router.push("/EnrolMfa");
      } else if (response.data.actionMfa) {
        this.$router.push(`/LoginMfa/${loginData.rememberMe}`);
      } else {
        this.errorMessage = response.data.faults || [];
        this.showErrors = true;
      }
    },
    showHelp() {
      this.$router.push({ name: "loginHelp" });
    },
  },
});
