
import * as Sentry from "@sentry/vue";
import type { SentryOptions } from "../swagger-gen/auth";
import { isEmpty, isNil } from "lodash";
import Vue from "vue";

/**
 * Sets ups sentry (for LS only)
 */
export function setUpSentry() {

  const sentryOptions = (window as unknown as { sentry: SentryOptions }).sentry;
  if (isNil(sentryOptions)) return;

  const environment = sentryOptions.environment || "unknown";
  const enableSentry = sentryOptions.enabled && !isEmpty(sentryOptions.dsn);

  Sentry.init({
    Vue,
    environment,
    dsn: sentryOptions.dsn || "",
    tracePropagationTargets: (sentryOptions.tracePropagationTargets || []).map(x => new RegExp(x)),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error", "warn"],
      }),
    ],
    tracesSampleRate: sentryOptions.tracesSampleRate || 1.0,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    enabled: enableSentry
  });
}
