import { CLButton, CLConfirmDialog, CLEnableMfa } from "@clearlife-limited/ui-library";
import mfaWithCompanyCheckComponent from "@clarinet/ts/Mixins/MfaWithCompanyCheckComponent";
import { MfaApi } from "@clarinet/ts/api";

export default mfaWithCompanyCheckComponent.extend({
  data() {
    return {
      showDisableMfaDialog: false,
    };
  },
  components: {
    "cl-button": CLButton,
    "cl-confirm-dialog": CLConfirmDialog,
    "cl-enablemfa": CLEnableMfa,
  },
  computed: {
    mfaLockReason(): string | null {
      return this.mfa.required
        ? "MFA is required by your company and cannot be disabled for your account."
        : null;
    },
  },
  methods: {
    async disableMfa() {
      await MfaApi.disable();
      await this.getMfaStatus();
    },
    openMfaHelp() {
      !!this.mfa.helpUrl && window.open(this.mfa.helpUrl);
    },
  },
});
