import type { UserAutocompleteDto, ModuleDto, RoleDto } from "@clarinet/swagger-gen/auth";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { SpecificColDef } from "@clearlife-limited/ui-library";
import {
  CLAutocomplete,
  CLExpander,
  CLExpanderPanel,
  CLDialog,
  CLConfirmDialog,
  CLButton,
  CLCheckbox,
  CLKeyline,
  CLTable
} from "@clearlife-limited/ui-library";
import type { GridOptions } from "@ag-grid-community/core";
import RoleCheckboxRenderer from "@clarinet/ts/Components/Renderers/RoleCheckboxRenderer.vue";
import type { RoleGridCell } from "@clarinet/ts/Components/Renderers/RoleCheckboxRenderer";
import { RolesApi, UsersApi } from "@clarinet/ts/api";

export interface RoleChanges {
    addRoles: string[];
    removeRoles: string[];
}

type ModuleGridRowCache = Record<string, GridRow[]>;
interface GridRow {
    displayName: string;
    readAccessRole?: RoleGridCell;
    editRole?: RoleGridCell;
    extraOptionsRole?: RoleGridCell;
}

interface AugmentedRoleDto extends RoleDto {
    highlightAdd: boolean | undefined;
    highlightDelete: boolean | undefined;
}
type AugmentedModuleDto = Omit<Omit<ModuleDto, "roles">, "submodules"> & {
    roles: AugmentedRoleDto[];
    submodules: AugmentedModuleDto[];
};

type UserSearch = {
  foundUsers: UserAutocompleteDto[];
  currentSearch: string | null;
  inProgress: boolean;
};

export default defineComponent({
  components: {
    ClAutocomplete: CLAutocomplete,
    ClExpander: CLExpander,
    ClExpanderPanel: CLExpanderPanel,
    ClDialog: CLDialog,
    ClButton: CLButton,
    ClCheckbox: CLCheckbox,
    ClConfirmDialog: CLConfirmDialog,
    ClKeyline: CLKeyline,
    ClTable: CLTable,
    RoleCheckboxRenderer,
  },
  props: {
    inEdit: {
      type: Boolean,
      required: true,
    },
    limitToUser: {
      type: Boolean,
      required: true,
    },
    userName: {
      type: String,
      default: "",
    },
    value: {
      type: <PropType<RoleChanges>>Object,
      required: true,
    },
    preselectRequiredRoles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTable: true,
      modules: <AugmentedModuleDto[]>[],
      targetUserRoles: <string[]>[],
      viewingUserRoles: <string[]>[],
      roleChanges: <string[]>[],
      rolesToRemove: <string[]>[],
      roleToRemove: <string | undefined>undefined,
      flatRoles: <AugmentedRoleDto[]>[],
      removeRolesDialog: false,
      showReplaceRolesDialog: false,
      selectedUser: <string | null>null,
      userSearch: <UserSearch>{
        foundUsers: [],
        currentSearch: null,
        inProgress: false,
      },
      gridOptions: <GridOptions<GridRow>>{
        domLayout: "autoHeight",
        defaultColDef: {
          sortable: false,
        },
        pagination: false,
        suppressMovableColumns: true,
        suppressPaginationPanel: true,
        enableRangeSelection: false,
      },
    };
  },
  computed: {
    columnDefs(): SpecificColDef<GridRow>[] {
      const roleCheckboxColDef = <SpecificColDef<GridRow>>{
        flex: 1,
        minWidth: 140,
        cellRenderer: "RoleCheckboxRenderer",
        // We don't need a valueFormatter as we have a custom renderer but AgGrid complains if we don't
        valueFormatter: () => "",
        useValueFormatterForExport: true,
        cellStyle: {
          margin: 0,
          padding: 0,
        },
      };

      return [
        {
          headerName: "",
          field: "displayName",
          flex: 1,
          minWidth: 260,
        },
        {
          headerName: "Read/access",
          field: "readAccessRole",
          ...roleCheckboxColDef,
        },
        {
          headerName: "Edit",
          field: "editRole",
          ...roleCheckboxColDef,
        },
        {
          headerName: "Extra Options",
          field: "extraOptionsRole",
          ...roleCheckboxColDef,
        },
      ];
    },
    gridRowsByModule(): ModuleGridRowCache {
      const gridRowsByModule = <ModuleGridRowCache>{};

      const roleGridCellForRole = (role: AugmentedRoleDto): RoleGridCell | undefined => {
        if (!role) return undefined;

        return <RoleGridCell>{
          name: role.name,
          value: this.isInRole(role.name),
          description: role.description,
          disabled: !this.canChangeRole(role.name, role.mutexGroup),
          tooltip: this.cannotChangeTooltip(role.name, role.mutexGroup),
          highlightAdd: role.highlightAdd,
          highlightDelete: role.highlightDelete,
        };
      };

      for (const mod of this.modules) {
        const moduleRows = mod.roles.map(
          (role) =>
            <GridRow>{
              displayName: role.displayName,
              readAccessRole: roleGridCellForRole(role),
            }
        );

        for (const subMod of mod.submodules) {
          moduleRows.push(<GridRow>{
            displayName: subMod.name,
            readAccessRole: subMod.roles.length > 0 ? roleGridCellForRole(subMod.roles[0]) : undefined,
            editRole: subMod.roles.length > 1 ? roleGridCellForRole(subMod.roles[1]) : undefined,
            extraOptionsRole: subMod.roles.length > 2 ? roleGridCellForRole(subMod.roles[2]) : undefined,
          });
        }

        gridRowsByModule[mod.name] = moduleRows;
      }

      return gridRowsByModule;
    },
    selectedTextByModule(): Record<string, string> {
      const result: Record<string, string> = {};

      this.modules.forEach((mod) => {
        const total = this.flattenedRoles(mod);
        const selectedCount = total.filter((role) => this.isInRole(role.name)).length;
        const allSelected = total.length === selectedCount;
        result[mod.name] = selectedCount === 0 ? "" : `${allSelected ? "All" : selectedCount} selected`;
      });

      return result;
    },
  },
  watch: {
    async userName() {
      this.targetUserRoles = await this.loadUserRoles();
    },
    "userSearch.currentSearch": {
      async handler(val: string) {
        this.userSearch.inProgress = true;
        const { data } = await UsersApi.search(val);
        this.userSearch.foundUsers = data.filter((u) => u.userName !== this.userName);
        this.userSearch.inProgress = false;
      },
    },
    async selectedUser() {
      if (!this.selectedUser) return;

      if (this.targetUserRoles.length > 0 || this.roleChanges.length > 0) {
        this.showReplaceRolesDialog = true;
      } else {
        await this.copyRolesFromAnotherUser();
      }
    },
    roleChanges() {
      const changes = <RoleChanges>{
        addRoles: [],
        removeRoles: [],
      };

      for (const role of this.roleChanges) {
        if (this.targetUserRoles.indexOf(role) >= 0) {
          changes.removeRoles.push(role);
        } else {
          changes.addRoles.push(role);
        }
      }

      this.$emit("input", changes);
    },
  },
  async mounted() {
    const [{ data: roleModules }, { data: viewingUser }] = await Promise.all([
      RolesApi.getAll(),
      RolesApi.getOwnRoles(),
    ]);
    this.modules = roleModules as AugmentedModuleDto[];
    this.flattenRoles();
    this.viewingUserRoles = viewingUser;
    this.targetUserRoles = await this.loadUserRoles();

    // if we do not have a user to load or user roles then select at least the essential.
    if (this.preselectRequiredRoles && !this.userName && this.targetUserRoles.length === 0) {
      this.selectUserRole();
    }
  },
  methods: {
    flattenRoles() {
      this.flatRoles = [];
      for (const mod of this.modules) {
        Array.prototype.push.apply(this.flatRoles, mod.roles);
        for (const subMod of mod.submodules) {
          Array.prototype.push.apply(this.flatRoles, subMod.roles);
        }
      }
    },
    findRole(roleName: string): AugmentedRoleDto | undefined {
      return this.flatRoles.find((r) => r.name === roleName);
    },
    findMutexRoles(mutexGroup: string): AugmentedRoleDto[] {
      return this.flatRoles.filter((r) => r.mutexGroup === mutexGroup);
    },
    dependsOnBadMutexOption(roleName: string): boolean {
      const role = this.findRole(roleName);
      if (!role) return false;
      if (role.mutexGroup) {
        for (const mrole of this.findMutexRoles(role.mutexGroup)) {
          if (mrole.name !== roleName && this.isInRole(mrole.name)) return true;
        }
      }
      for (const drole of role.dependsOnRoleNames) {
        if (this.dependsOnBadMutexOption(drole)) return true;
      }
      return false;
    },
    canChangeRole(roleName: string, mutexGroup: string | undefined): boolean {
      if (mutexGroup) {
        for (const role of this.findMutexRoles(mutexGroup)) {
          if (role.name !== roleName && this.isInRole(role.name)) return false;
        }
      }
      if (this.dependsOnBadMutexOption(roleName)) return false;
      return this.canChangeRoleIgnoringMutex(roleName);
    },
    canChangeRoleIgnoringMutex(roleName: string): boolean {
      if (!this.limitToUser) return true;
      if (!this.inEdit) return false;

      return this.viewingUserRoles.indexOf(roleName) >= 0;
    },
    cannotChangeTooltip(roleName: string, mutexGroup?: string): string | null {
      if (mutexGroup) {
        for (const role of this.findMutexRoles(mutexGroup)) {
          if (role.name !== roleName && this.isInRole(role.name))
            return `Cannot be selected with ${role.displayName}`;
        }
      }
      if (this.dependsOnBadMutexOption(roleName)) return "Depends on one or more roles which cannot currently be selected";
      if (!this.limitToUser) return null;
      if (!this.inEdit) return "You cannot edit your own roles";

      if (this.viewingUserRoles.indexOf(roleName) < 0)
        return "You do not have permissions to assign this role to a user. If you require this functionality please contact ClearLife";
      return null;
    },
    isInRole(roleName: string) {
      return this.targetUserRoles.indexOf(roleName) >= 0 !== this.roleChanges.indexOf(roleName) >= 0;
    },
    tryChangeRoleState(roleName: string) {
      if (!this.isInRole(roleName)) {
        this.addRole(roleName, false);
      } else {
        this.roleToRemove = roleName;
        this.rolesToRemove = [];
        this.findRoleRemovalDependencies(roleName, false);
        if (this.rolesToRemove.length === 0) {
          this.removeRole(roleName, false);
        } else {
          this.removeRolesDialog = true;
        }
      }
    },
    changeRoleState(dto: AugmentedRoleDto) {
      dto.highlightAdd = false;
      dto.highlightDelete = false;

      const changeIndex = this.roleChanges.indexOf(dto.name);

      if (changeIndex >= 0) {
        this.roleChanges.splice(changeIndex, 1);
      } else {
        this.roleChanges.push(dto.name);
      }
    },
    addRole(roleName: string, withHighlight: boolean) {
      const dto = this.findRole(roleName);
      if (!dto) return;

      this.roleToRemove = undefined;
      this.changeRoleState(dto);

      dto.highlightAdd = withHighlight;

      for (const requiredRole of dto.dependsOnRoleNames) {
        if (!this.isInRole(requiredRole)) this.addRole(requiredRole, true);
      }
    },
    removeRole(roleName: string, withHighlight: boolean) {
      const dto = this.findRole(roleName);
      if (!dto) return;

      this.removeRolesDialog = false;
      this.roleToRemove = undefined;
      this.changeRoleState(dto);

      dto.highlightDelete = withHighlight;

      for (const requiredRole of dto.requiredByRoleNames) {
        if (this.isInRole(requiredRole)) this.removeRole(requiredRole, true);
      }
    },
    findRoleRemovalDependencies(roleName: string, addToList: boolean) {
      const dto = this.findRole(roleName);
      if (!dto) return;
      if (addToList) {
        if (this.rolesToRemove.indexOf(dto.displayName) >= 0) return;
        this.rolesToRemove.push(dto.displayName);
      }
      for (const requiredRole of dto.requiredByRoleNames) {
        if (this.isInRole(requiredRole)) this.findRoleRemovalDependencies(requiredRole, true);
      }
    },
    cancelRemoveRolesDialog(roleName: string) {
      this.removeRolesDialog = false;
      this.rolesToRemove = [];
      if (this.roleToRemove === roleName) {
        this.roleToRemove = undefined;
        // re-renders the table to correctly re-update all the checkboxes
        this.showTable = false;
        this.$nextTick(() => this.showTable = true);
        return;
      }
      if (!this.roleToRemove) return;

      this.roleChanges.splice(this.roleChanges.indexOf(this.roleToRemove), 1);
      this.$nextTick(() => {
        if (!this.roleToRemove) return;

        this.roleChanges.push(this.roleToRemove);
        this.roleToRemove = undefined;
      });
    },
    async loadUserRoles(userName?: string | null): Promise<string[]> {
      // If we're only loading the current user and we already have their roles, don't reload them
      if (!userName && this.targetUserRoles.length > 0) return this.targetUserRoles;

      userName = userName || this.userName;

      if (userName) {
        const { data } = await RolesApi.getUserRoles(userName);
        return data;
      }

      return this.targetUserRoles;
    },
    async copyRolesFromAnotherUser() {
      const newRoles = await this.loadUserRoles(this.selectedUser);

      this.flatRoles.forEach((role) => {
        const targetUserHas = this.isInRole(role.name);
        const selectedUserHas = newRoles.indexOf(role.name) >= 0;

        if (targetUserHas === selectedUserHas || !this.canChangeRoleIgnoringMutex(role.name)) {
          return;
        }

        const dto = this.findRole(role.name);
        if (!dto) return;
        this.changeRoleState(dto);
        dto.highlightAdd = selectedUserHas;
        dto.highlightDelete = !selectedUserHas;
      });

      this.selectedUser = null;
    },
    undo() {
      this.roleChanges = [];
    },
    flattenedRoles(module: ModuleDto): AugmentedRoleDto[] {
      return module.roles.concat(module.submodules.flatMap((m) => m.roles)).map((r) => r as AugmentedRoleDto);
    },
    selectAllInModule(moduleName: string) {
      const mod = this.modules.find((m) => m.name === moduleName);
      if (!mod) return;
      for (const role of this.flattenedRoles(mod)) {
        if (role.mutexGroup && role.mutexGroup !== role.name) continue;
        if (!this.isInRole(role.name) && this.canChangeRole(role.name, role.mutexGroup))
          this.changeRoleState(role);
      }
    },
    selectUserRole() {
      const mod = this.modules.find((m) => m.name === "Essentials");
      if (!mod) return;
      const role = this.flattenedRoles(mod).find((role) => role.name === "User");
      if (role && !this.isInRole(role.name) && this.canChangeRole(role.name, role.mutexGroup)) {
        this.changeRoleState(role);
      }
    },
    clearAllInModule(moduleName: string) {
      const mod = this.modules.find((m) => m.name === moduleName);
      if (!mod) return;
      for (const role of this.flattenedRoles(mod)) {
        if (this.isInRole(role.name) && this.canChangeRole(role.name, role.mutexGroup))
          this.changeRoleState(role);
      }
    },
    updateTargetUserRoles(roleName: string) {
      this.tryChangeRoleState(roleName);
      this.flatRoles.forEach((role) => {
        role.highlightAdd = false;
        role.highlightDelete = false;
      });
    },
  },
});
