type HttpMethod = "POST" | "GET" | "PUT";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onComplete(request: XMLHttpRequest, reject: (reason?: any) => void, resolve: () => void) {
  if (request.status != 200) {
    const defaultResponse = { statusText: request.statusText, status: request.status };
    if (request.response.type === "application/json") {
      const reader = new FileReader();
      reader.readAsText(request.response);
      reader.onload = function () {
        if (reader.result && !reader.error) {
          reject(JSON.parse(<string>reader.result));
        } else {
          reject(defaultResponse);
        }
      };
    } else {
      reject(defaultResponse);
    }
    return;
  }

  const contentType = request.getResponseHeader("Content-Type");
  const contentDisposition = request.getResponseHeader("Content-Disposition");
  const fileNameExtractor = /^Attachment;[ ]?Filename="(.+)"/i;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = fileNameExtractor.exec(contentDisposition!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const blob = new Blob([request.response], { type: contentType! });
  const downloadAnchor = document.createElement("a");
  downloadAnchor.style.display = "none";
  document.body.appendChild(downloadAnchor);
  const url = window.URL.createObjectURL(blob);
  downloadAnchor.href = url;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  downloadAnchor.download = match![1];
  downloadAnchor.click();
  window.URL.revokeObjectURL(url);
  resolve();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function download(url: string, data: any = undefined, method: HttpMethod = "POST"): Promise<void> {
  const request = new XMLHttpRequest();
  return new Promise(function (resolve, reject) {
    request.open(method, url);
    request.responseType = "blob";
    request.setRequestHeader("Content-Type", "text/json");
    request.send(data ? JSON.stringify(data) : null);
    request.onload = () => onComplete(request, reject, resolve);
  });
}
