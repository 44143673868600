import Vue from "vue";
import type { AxiosError } from "axios";
import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/ts/State/Stores/AuthStore";
import type { CLAccountSetupData } from "@clearlife-limited/ui-library";
import { CLCreateAccount } from "@clearlife-limited/ui-library";
import { InviteApi } from "@clarinet/ts/api";
import axios from "axios";

export type ErrorResponse = {
    message: string;
    isPasswordError: boolean;
};
export default Vue.extend({
  components: {
    ClCreateaccount: CLCreateAccount,
  },
  data() {
    return {
      acceptanceToken: <string | null>null,
      emailAddress: <string | null>null,
      errorMessage: <string | null>null,
      passwordErrorMessage: <string | null>null,
      userId: <string | null>null,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId as string;
      this.emailAddress = this.$route.query.e as string;
      this.acceptanceToken = this.$route.query.t as string;
    } else {
      this.$router.replace("/Login/ClariNet%20LS");
    }
  },
  methods: {
    async createAccount(setupData: CLAccountSetupData) {
      this.errorMessage = null;
      this.passwordErrorMessage = null;

      const payload = {
        userName: setupData.username,
        emailAddress: setupData.email,
        newPassword: setupData.password,
        newPasswordConfirmed: setupData.confirmPassword,
        acceptanceToken: this.acceptanceToken,
      };

      try {
        const response = await InviteApi.acceptInvitation(this.userId || "", payload);

        if (response.data.requireMfa) {
          if (this.userId) this.authStore.userId = this.userId;
          this.authStore.returnUrl = "/RegistrationComplete";
          await this.$router.replace("/EnrolMfa");
        } else {
          await this.$router.replace("/RegistrationComplete");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ value: ErrorResponse }>;
          if (axiosError.response?.status === 400 && axiosError.response.data) {
            const error = axiosError.response.data.value;
            if (error.isPasswordError) {
              this.passwordErrorMessage = error.message;
            } else {
              this.errorMessage = error.message;
            }
          } else {
            this.errorMessage = "Sorry, something went wrong with your registration. Please try again. If you continue to have errors please contact support.";
          }
        }
      }
    },
  },
});
