import mfaComponent from "@clarinet/ts/Mixins/MfaComponent";
import { OrganisationsApi } from "@clarinet/ts/api";

export default mfaComponent.extend({
  data() {
    return {
      mfa: {
        required: <boolean | undefined>false,
      },
    };
  },
  async mounted() {
    const companyStatus = await OrganisationsApi.getMfaRequired();
    this.mfa.required = companyStatus.data;
  },
});
