<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <ServerError />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ServerError from "./Components/ServerError.vue";
import { getAndUpdateFontSize } from "./Utility/UIPreferences";

export default defineComponent({
  components: { ServerError }
});
getAndUpdateFontSize();
</script>
