import Vue from "vue";
import { CLForgotUsername, CLForgotUsernameEmailSent } from "@clearlife-limited/ui-library";
import { LoginApi } from "@clarinet/ts/api";

export default Vue.extend({
  components: {
    ClForgotusername: CLForgotUsername,
    ClEmailsent: CLForgotUsernameEmailSent,
  },
  data() {
    return {
      emailSent: false,
      emailAddress: "",
    };
  },
  methods: {
    async sendEmail(emailAddress: string) {
      const response = await LoginApi.forgotUsername({ emailAddress: emailAddress });
      if (response.status === 200) {
        this.emailAddress = emailAddress;
        this.emailSent = true;
      }
    },
    returnToLogin() {
      this.$router.go(-2);
    },
  },
});
