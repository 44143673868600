import Vue from "vue";
import { CLHelp } from "@clearlife-limited/ui-library";

export default Vue.extend({
  components: {
    ClHelp: CLHelp,
  },
  data() {
    return {};
  },
  methods: {
    returnToLogin() {
      this.$router.go(-1);
    },
    forgotPassword() {
      this.$router.push("/ForgottenPassword");
    },
    forgotUsername() {
      this.$router.push("/ForgottenUsername");
    },
    receivedInvite() {
      this.$router.push("/ReceivedInvite");
    },
  },
});
