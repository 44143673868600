import Vue from "vue";
import type { ICellRendererParams } from "@ag-grid-community/core";
import { CLCheckbox, CLGridActionButton } from "@clearlife-limited/ui-library";

export interface RoleGridCell {
    name: string;
    value: boolean;
    description?: string;
    disabled: boolean;
    highlightAdd: boolean;
    highlightDelete: boolean;
    tooltip?: string;
}

type RoleCheckboxRendererParams = Omit<ICellRendererParams, "value"> & {
    value?: RoleGridCell;
};

export default Vue.extend({
  components: {
    ClCheckbox: CLCheckbox,
    ClGridActionButton: CLGridActionButton,
  },
  data() {
    return {
      params: <RoleCheckboxRendererParams>{},
    };
  },
  computed: {
    checkboxClasses(): Record<string, boolean> {
      return {
        "highlight-add": this.params.value?.highlightAdd || false,
        "highlight-delete": this.params.value?.highlightDelete || false,
      };
    },
    checkboxColor(): string | undefined {
      if (this.params.value?.highlightAdd) return "green";
      if (this.params.value?.highlightDelete) return "red";
      return undefined;
    },
  },
  beforeMount() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.params = <RoleCheckboxRendererParams>(<any>this).params;
  },
  methods: {
    emitUpdate() {
      this.$parent?.$parent?.$emit("update", this.params.value?.name);
    },
  },
});
